import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, Grid, Drawer, IconButton, Badge, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IMAGES } from '../../asset';
import { callApi } from '../../Services/Api';
import { Styles, theme } from '../../Utils/styles';

interface NavigationParams {
    section: string;
}
interface ActiveSectionItem {
    activeSection: string;
    setActiveSection: (section: string) => void;
    messageData: Alarm[],
    machineType: string;
    configData: ConfigData | undefined;
}
interface ControlData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
    display_format: string;
    display_unit: string;
    width: string;
    height: string;
    animate: string;
    image: string;
    pos_x: string;
    pos_y: string;
    z_index: string;
}

interface ConfigData {
    views: {
        id: string;
        text: string;
        controls: ControlData[]
    }[];
}
interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}
interface ImageProps {
    iconActive: string | undefined;
    iconDefault: string | undefined;
}
const Header: React.FC<ActiveSectionItem> = ({ setActiveSection, activeSection, messageData, machineType, configData }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [errorAlarmCount, setErrorAlarmCount] = useState<number>(0);
    const [coaterName, setCoaterName] = useState<string>("")
    const handleNavigation = ({ section }: NavigationParams) => {
        setActiveSection(section);
    };
    const fetchCoaterName = async () => {
        try {
            const eventsPayload = {
                endpoint: "info",
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Prefer': `code=200, example=${machineType}_machine_info`
                }
            };
            const eventsResponse = await callApi(eventsPayload);
            setCoaterName(eventsResponse.model);
        } catch (error) {
            setCoaterName("null")
            console.log(error);
        }
    }
    const handleAlarmCount = () => {
        const error = messageData.filter(alarm => alarm.level === 'error');
        setErrorAlarmCount(error.length)
    }
    useEffect(() => {
        fetchCoaterName();
        // const timer = setInterval(() => {
        //     fetchCoaterName();
        // }, 5000);
        // return () => {
        //     clearInterval(timer)
        // }
    }, [machineType]);
    useEffect(() => {
        handleAlarmCount();
    }, [messageData])
    const HeaderImage: ImageProps[] = [
        {
            iconActive: IMAGES.processActive,
            iconDefault: IMAGES.process
        },
        {
            iconActive: IMAGES.pumpActive,
            iconDefault: IMAGES.pump
        },

    ];
    const isPortrait = useMediaQuery('(orientation: portrait)');
    return (
        <Stack sx={Styles.root}>
            <header>
                <div className="header-left">
                    <Grid sx={Styles.desktopMenu}>
                        <Box sx={Styles.coaterStyle}>
                            <Typography variant='body1' sx={Styles.coaterText}>
                                {coaterName}
                            </Typography>
                        </Box>
                        <>

                            {isPortrait ?
                                configData && configData.views.filter((item) => item.text === "Process").map((item, index) => {
                                    return <Box key={item.id}
                                        data-testid={item.text}
                                        sx={{ ...Styles.roleProfile, ...(activeSection === item.text && Styles.activeBtn) }}
                                        onClick={() => handleNavigation({ section: item.text })}
                                    >
                                        <Stack>
                                            <img src={activeSection === item.text ? HeaderImage[index].iconActive : HeaderImage[index].iconDefault} alt='' height='32px' />
                                            <Typography variant='body1' sx={Styles.navBtns}>
                                                {item.text}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                }) :
                                configData && configData.views.map((item, index) => {
                                    return <Box key={item.id}
                                        data-testid={item.text}
                                        sx={{ ...Styles.roleProfile, ...(activeSection === item.text && Styles.activeBtn) }}
                                        onClick={() => handleNavigation({ section: item.text })}
                                    >
                                        <Stack>
                                            <img src={activeSection === item.text ? HeaderImage[index].iconActive : HeaderImage[index].iconDefault} alt='' height='32px' />
                                            <Typography variant='body1' sx={Styles.navBtns}>
                                                {item.text}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                })}
                        </>
                    </Grid>
                    <Grid sx={Styles.rightBox}>
                        <Badge sx={{
                            '& .MuiBadge-badge': {
                                height: '25px',
                                width: '25px',
                                backgroundColor: theme.palette.activeBtn.borderColor,
                                color: 'white',
                                borderRadius: '50%'
                            },
                        }} badgeContent={errorAlarmCount} max={99}>
                            <Box data-testid="Alarms"
                                sx={{ ...Styles.roleProfile, ...(activeSection === 'alarms' && Styles.activeBtn) }}
                                onClick={() => handleNavigation({ section: 'alarms' })}
                            >
                                <Stack>
                                    <img src={activeSection === 'alarms' ? IMAGES.alarmActive : IMAGES.alarm} alt='' height='32px' />

                                    <Typography variant='body1' sx={Styles.navBtns}>
                                        {"Alarms"}
                                    </Typography>
                                </Stack>

                            </Box>
                        </Badge>
                        <img style={Styles.logoIcon} src={IMAGES.oerlikonLogo} title="Logo" alt='logo' height='32px' />
                    </Grid>
                </div>
                <Box sx={Styles.headerRight}>
                    <IconButton
                        sx={Styles.mobileMenuButton}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
            </header>
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box sx={{ width: 250 }}>
                    <IconButton
                        onClick={() => setDrawerOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {
                        configData && configData.views.map((item, index) => {
                            return <Box key={item.id}
                                data-testid={item.text}
                                sx={{ ...Styles.roleProfile, ...(activeSection === item.text && Styles.activeBtn) }}
                                onClick={() => { handleNavigation({ section: item.text }); setDrawerOpen(false); }}
                            >
                                <Stack>
                                    <img src={activeSection === item.text ? HeaderImage[index].iconActive : HeaderImage[index].iconDefault} alt='' height='32px' />
                                    <Typography variant='body1' sx={Styles.navBtns}>
                                        {item.text}
                                    </Typography>
                                </Stack>
                            </Box>
                        })
                    }

                </Box>
            </Drawer>
        </Stack>
    );
};

export default Header;
