
import { PaginationItem } from '@mui/material';
import { styled } from '@mui/system';
import { style } from '../../Utils/styles';

const CustomPaginationItem = styled(PaginationItem)(() => ({
    '&.Mui-selected': {
        backgroundColor: style.palette.activeBtn.borderColor,
        color: style.palette.Neutral,
        borderRadius: '8px'
    },
    '&': {
        color: style.palette.activeBtn.borderColor,
        fontSize: '16px',
    },
}));

export default CustomPaginationItem;
