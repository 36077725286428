import React from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Styles } from '../../Utils/styles';

interface ProcessAllData {
    id: string;
    unit?: string;
    value: string;
    text: string;
    value2?: string;
}
interface PostSequenceProps {
    postSequence: ProcessAllData[];
}
const PostSequence: React.FC<PostSequenceProps> = ({ postSequence }) => {
    const isPortrait = useMediaQuery('(orientation: portrait)');
    return (
        <Box sx={{
            overflowX: 'auto', position: 'relative', ...(isPortrait ? {
                width: '60%', display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            } : {})
        }} data-testid="postSequence-component">
            <Box sx={{ ...Styles.postProcess, }}>
                {
                    postSequence?.map((item: ProcessAllData) => {
                        const isRejectStyle = ['False', 'Off', 'Error'].includes(item.value);
                        const paperStyle = isRejectStyle ? Styles.rejectStyle : Styles.successStyle;
                        const boxStyle = isRejectStyle ? Styles.InprocessBox : Styles.CompleteBox;
                        return <Grid key={item.id} xs={4}>
                            <Box sx={{ ...Styles.postProcessDetails, margin: '5px', ...Styles.mainPostProcess, ...paperStyle }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%'
                                }}>
                                    <Typography variant="body1" sx={Styles.message}>{item.text}</Typography>
                                    <Box sx={boxStyle}>{item?.value == "True" ? "Yes" : "No"}</Box>
                                </Box>
                            </Box>
                        </Grid>
                    })
                }
            </Box>
            <Button sx={{ ...Styles.SystemButton, ...(isPortrait ? Styles.processBtnPortrait : {}) }} variant="contained" disabled>Edit post sequence</Button>
        </Box>
    )
}
export default PostSequence;