import React from 'react';
import { Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery } from "@mui/material";
import { IMAGES } from '../../asset';
import { Styles } from '../../Utils/styles';
import CustomPaginationItem from '../Pagination/CustomPaginationItem';

interface AlertsData {
    messageData: Alarm[];
    setPage: (section: number) => void;
    page: number;
    totalAlerts: number;
    itemsPerPage: number;
    activeSection?: string;
}

export interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

const formatDate = (dateString: string | null): string => {
    if (!dateString) return "null";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const AlertsTable: React.FC<AlertsData> = ({ messageData, setPage, page, totalAlerts, itemsPerPage, activeSection }) => {
    const previousComponent = () => <span>Prev</span>;
    const nextComponent = () => <span>Next</span>;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const isPortrait = useMediaQuery('(orientation: portrait)');
    return (
        <>
            <TableContainer sx={{ ...Styles.alertsMainComponent, ...(activeSection == 'Machine' || isPortrait ? { height: 'auto' } : {}) }} data-testid="Alerts-table">
                {messageData.length === 0 ? (
                    <Typography variant="h6" sx={Styles.noAlertsText}>No critical alerts</Typography>
                ) : (
                    <Table sx={{ width: '100%' }}>
                        <TableBody>
                            {messageData.map((alarm) => (
                                <TableRow key={alarm.id} sx={{ verticalAlign: 'baseline' }} data-testid="alert-row">
                                    <TableCell sx={{ ...Styles.alertText, width: '145px' }}>{formatDate(alarm.datetime)}</TableCell>
                                    <TableCell sx={{ ...Styles.alertText, position: 'relative', width: '145px' }}>
                                        <Typography sx={{ ...Styles.state, ...Styles.critical, width: '80px' }}>
                                            <div style={{ ...Styles.iconDiv }}>
                                                <img src={IMAGES.errorIcon} alt="alert-alarm" style={Styles.icon} />
                                            </div>
                                            {alarm.level}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...Styles.alertText, ...Styles.iconDescription, width: '450px' }}>
                                        {alarm.text}
                                    </TableCell>
                                    <TableCell sx={{ ...Styles.alertText, width: '145px' }}>{alarm.code}</TableCell>
                                    <TableCell sx={{ ...Styles.alertText, width: '145px' }}>{alarm.category}</TableCell>
                                    <TableCell sx={{ ...Styles.alertText }}>{alarm.type}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>

            <Stack spacing={2} sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '10px' }}>
                <Pagination
                    count={Math.ceil(totalAlerts / itemsPerPage)}
                    page={page}
                    onChange={handleChange}
                    renderItem={(item) => (
                        <CustomPaginationItem
                            {...item}
                            components={{ previous: previousComponent, next: nextComponent }}
                        />
                    )}
                />
            </Stack>
        </>
    );
};

export default AlertsTable;
