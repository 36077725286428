import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CriticalAlerts from '../../Component/CriticalAlerts/CriticalAlerts';
import ImageBox from './ImageBox';
import { style, Styles } from '../../Utils/styles';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: style.palette.BgColor,
});

interface PumpGasAllData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
    display_format: string;
    display_unit: string;
    width: string;
    height: string;
    animate: string;
    image: string;
    pos_x: string;
    pos_y: string;
    z_index: string;
    unit: string;
    value: string;
}

interface PumpGasProps {
    messageData: Alarm[];
    loading: boolean;
    pumpGasData: PumpGasAllData[];
    activeSection: string;
}

interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

const PumpGas: React.FC<PumpGasProps> = (props) => {

    return (
        <Container data-testid="MachineComponent">
            {/* <Typography variant="body1" gutterBottom sx={Styles.mainHeading}>
                Pump and Gas
            </Typography> */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                position: 'relative'
            }}>
                <ImageBox pumpGasData={props.pumpGasData} />
            </Box >
            <Grid sx={{ width: '100%', marginTop: '30px' }}>
                <Paper sx={{ padding: '20px' }}>
                    <Typography sx={Styles.alertHeader} variant="h6">Critical Alerts</Typography>
                    <Divider orientation="horizontal" flexItem />
                    <CriticalAlerts messageData={props.messageData} loading={props.loading} activeSection={props.activeSection} />
                </Paper>
            </Grid>
        </Container >
    );
};

export default PumpGas;
