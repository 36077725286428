import React from 'react';
import { Typography, Grid, Paper, Box, useMediaQuery } from '@mui/material';
import { IMAGES } from '../../asset';
import { classes, Styles } from '../../Utils/styles';

interface StatusProps {
    message: string;
    icon: string | undefined;
}
interface SystemData {
    systemDetails: ProcessAllData[];
}
interface ProcessAllData {
    id: string;
    unit: string;
    value: string;
    text: string;
    value2: string;
}
const SystemStatus: React.FC<SystemData> = ({ systemDetails }) => {
    const statusItemsImage: StatusProps[] = [
        {
            message: 'Doors Closed',
            icon: IMAGES.doorClosed,
        },
        {
            message: 'Process Water',
            icon: IMAGES.processWater,
        },
        {
            message: 'Leak Rates',
            icon: IMAGES.leakRates,
        },
        {
            message: 'Contamination',
            icon: IMAGES.contamination,
        },
        {
            message: 'Doors Closed',
            icon: IMAGES.doorClosed,
        },
        {
            message: 'Process Water',
            icon: IMAGES.processWater,
        },
        {
            message: 'Leak Rates',
            icon: IMAGES.leakRates,
        },
        {
            message: 'Contamination',
            icon: IMAGES.contamination,
        },
    ];

    // Utility function to add hyphen in long words
    const insertHyphen = (text: string, maxLength: number, hyphenPosition: number): string => {
        // Check if text has multiple words
        const words = text.split(' ');
        if (words.length === 1 && text.length > maxLength) {
            const firstPart = text.slice(0, hyphenPosition);
            const secondPart = text.slice(hyphenPosition);
            return `${firstPart}-${secondPart}`;
        }
        return text;
    };
    const isPortrait = useMediaQuery('(orientation: portrait)');
    return (
        <Paper className={classes.paper} sx={{ position: 'relative' }} data-testid="system-status">
            <Typography variant="h6" sx={Styles.AlignLeft}>System Status</Typography>

            <Grid className='systemStatusBox' container spacing={3} sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '0px', overflowY: 'auto', height: '88%', flexWrap: 'nowrap', gap: '10px 0'
            }}>
                {
                    systemDetails.length > 0 ? (
                        systemDetails.map((item, index) => {
                            const dynamicText = item.text.includes('[x]')
                                ? item.text.replace('[x]', item.value2)
                                : item.text;

                            const formattedText = insertHyphen(dynamicText, 12, 5);

                            const displayText = item.value === 'True'
                                ? 'OK'
                                : item.value === 'False'
                                    ? 'Not OK'
                                    : item.value;

                            // style based on the item value
                            const isRejectStyle = ['False', 'Off', 'Error'].includes(item.value);
                            const paperStyle = isRejectStyle ? Styles.rejectStyle : Styles.successStyle;
                            const boxStyle = isRejectStyle ? Styles.InprocessBox : Styles.CompleteBox;
                            return (
                                <Grid key={item.id} item sx={{ width: "100%", ...(systemDetails.length > 3 ? { paddingLeft: '12px !important' } : {}) }}>
                                    <Paper className={classes.paper} sx={{
                                        ...Styles.systemBox, ...paperStyle, ...(systemDetails.length > 3 ? { padding: '24px 12px !important' } : {})
                                    }}>
                                        <Box sx={Styles.systemIconBox}>
                                            <img style={Styles.systemIcon} src={statusItemsImage[index]?.icon} alt="icon" />
                                            <Typography variant="body1" sx={Styles.message}>{isPortrait ? formattedText : dynamicText}</Typography>
                                        </Box>
                                        <Box sx={boxStyle}>
                                            {displayText}
                                        </Box>
                                    </Paper>
                                </Grid>
                            );
                        })
                    ) : (
                        <Typography variant="body1" sx={Styles.message}>No data available</Typography>
                    )
                }
            </Grid>
        </Paper>
    );
};

export default SystemStatus;
