import React from 'react';
import { Container, Grid, Paper, Box, Typography, useMediaQuery } from '@mui/material';
import SystemStatus from '../../Component/SystemStatus/SystemStatus';
import Divider from '@mui/material/Divider';
import CriticalAlerts from '../../Component/CriticalAlerts/CriticalAlerts';
// import PostSequence from '../../Component/PostSequence/PostSequence';
import ProcessStep from '../../Component/ProcessStep/ProcessStep';
import { Root, classes, Styles } from '../../Utils/styles';
import ProcessStep2 from '../../Component/ProcessStep/ProcessStep2';
import ImageBox from '../PumpGas/ImageBox';

interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

interface ControlData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
}
interface ProcessAllData {
    id: string;
    unit: string;
    value: string;
    text: string;
    value2: string;
}
interface processDataItem {
    messageData: Alarm[];
    loading: boolean;
    controlData: ControlData[];
    labels: string[];
    systemStatusMainAllData: ProcessAllData[];
    postSequenceMainAllData: ProcessAllData[];
    processObjectsMainAllData: ProcessAllData[];
    machineType: string;
    pumpGasData: PumpGasAllData[];
}
interface PumpGasAllData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
    display_format: string;
    display_unit: string;
    width: string;
    height: string;
    animate: string;
    image: string;
    pos_x: string;
    pos_y: string;
    z_index: string;
    unit: string;
    value: string;
}

const Process: React.FC<processDataItem> = (props) => {
    const isPortrait = useMediaQuery('(orientation: portrait)');

    return (
        <Root className={classes.root} data-testid="ProcessComponent">
            <Container sx={Styles.mainContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={isPortrait ? 12 : 4}>
                        {props.machineType !== 'BAL' ? <ProcessStep2 labels={props.labels} processAllData={props.processObjectsMainAllData} />
                            : <ProcessStep labels={props.labels} processAllData={props.processObjectsMainAllData} postSequenceAllData={props.postSequenceMainAllData} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={isPortrait ? 12 : 8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SystemStatus systemDetails={props.systemStatusMainAllData} />
                            </Grid>

                            {isPortrait &&
                                <Box sx={{
                                    width: '100%',
                                    paddingTop: '40px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <ImageBox pumpGasData={props.pumpGasData} />
                                </Box>}
                            <Grid item xs={12} sx={isPortrait ? { paddingTop: '0px !important' } : {}}>
                                <Paper className={classes.paper}>
                                    <Typography sx={Styles.alertHeader} variant="h6">Critical Alerts</Typography>
                                    <Divider orientation="horizontal" flexItem />
                                    <CriticalAlerts messageData={props.messageData} loading={props.loading} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
};

export default Process;
