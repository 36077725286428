import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import SegmentedProgressBar from '../Progress/SegmentedProgressBar';
import { classes, style, Styles } from '../../Utils/styles';
import PostSequence from '../PostSequence/PostSequence';
import GaugeContainer from '../Progress/GaugeContainer';
import { useLocation } from 'react-router-dom';

interface ProcessStepProps {
    labels: string[];
    processAllData: ProcessAllData[];
    postSequenceAllData: ProcessAllData[];
}

interface ProcessAllData {
    id: string;
    unit?: string;
    value: string;
    text: string;
    value2?: string;
}

interface ProcessValues {
    [key: string]: { value: string, text: string };
}

// Utility function to format milliseconds to a readable time format
const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
        return `${hours}hr ${minutes}min`;
    } else if (minutes > 0) {
        return `${minutes}min`;
    } else {
        return `${seconds}sec`;
    }
};

const ProcessStep: React.FC<ProcessStepProps> = (props) => {
    const [processValues, setProcessValues] = useState<ProcessValues>({});
    const [view, setView] = useState<string | null>('process'); // Initial view state
    const isPortrait = useMediaQuery('(orientation: portrait)');

    useEffect(() => {
        // Function to extract values and texts based on keys
        const extractValues = (data: ProcessAllData[], keys: string[]): ProcessValues => {
            const result: ProcessValues = {};
            keys.forEach(key => {
                const item = data.find(obj => obj.id === key);
                if (item) {
                    result[key] = { value: item.value, text: item.text };
                }
            });
            return result;
        };

        // Extract processValues whenever processAllData changes
        const keysToExtract = [
            'process_RecipeName',
            'process_GaugeStepCounter',
            'process_GaugeStepTotal',
            'process_GaugeSeqTimerLen',
            'process_GaugeSeqTimerRemaining',
            'process_GaugeSeqTimerElapsed',
            'process_GaugeCurrentBatchFinalState',
            'process_BatchState',
            'process_BatchNumber',
            'process_CarouselNumber',
            'process_BatchRestart',
        ];

        const updatedValues = extractValues(props.processAllData, keysToExtract);
        setProcessValues(updatedValues);
    }, [props.processAllData]);

    const isMarqueeRequired = (text: string) => {
        const threshold = isPortrait ? 15 : 10;
        return text?.length > threshold;
    };
    const isMarqueeRequiredHead = (text: string) => {
        const threshold = 40;
        return text?.length > threshold;
    };
    // Determine button state and styles
    const isButtonDisabled = processValues['process_BatchState']?.value === 'Idle';
    const buttonText = isButtonDisabled ? 'Start new process' : 'Stop process';
    const buttonStyles = {
        ...Styles.SystemButton,
        backgroundColor: isButtonDisabled ? style.palette.progressBar.main : style.palette.logoColor.main,
    };

    const remainingTime = processValues['process_GaugeSeqTimerRemaining']?.value;
    const formattedRemainingTime = remainingTime ? formatTime(parseInt(remainingTime, 10)) : '';

    const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
        if (newView !== null) {
            setView(newView);
        }
    };

    const location = useLocation();
    return (
        <Paper className={classes.paper} sx={{ height: '840px', overflowX: 'hidden', position: 'relative', ...(isPortrait ? { height: 'inherit' } : {}) }} data-testid="process-step">
            <Typography variant="h6" sx={Styles.AlignLeft}>{view === 'process' ? "Process Status" : "Post Sequence"}</Typography>
            <Box sx={{ width: isPortrait ? '40%' : '100%', overflowX: 'hidden' }}>
                <Typography variant="h6" sx={isMarqueeRequiredHead(processValues['process_RecipeName']?.value)
                    ? { ...Styles.subHeader, ...Styles.marqueeHeader }
                    : Styles.subHeader
                }>
                    {processValues['process_RecipeName']?.value}
                </Typography>
            </Box>
            <Box sx={{
                ...(isPortrait ? Styles.ProcessPortraitStyle : {})
            }}>
                <Box sx={{
                    ...Styles.progressBox, margin: '20px 0 0 0',
                    ...(isPortrait && location.pathname !== "/Gauge" ? { margin: '30px 0', marginLeft: '55px' } : {})
                }}>
                    {location.pathname == "/Gauge" ?
                        <GaugeContainer labels={props.labels} timeRemaining={processValues['process_GaugeSeqTimerRemaining']?.value} counter={processValues['process_GaugeSeqTimerElapsed']?.value} total={processValues['process_GaugeSeqTimerLen']?.value} />
                        :
                        <>
                            <SegmentedProgressBar
                                segments={props.labels.length}
                                value={parseInt(processValues['process_GaugeSeqTimerElapsed']?.value, 10)}
                                total={parseInt(processValues['process_GaugeSeqTimerLen']?.value, 10)}
                                radius={100}
                                strokeWidth={20}
                                spaceBetween={1}
                                labels={props.labels}
                                colors={[]}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography variant="h6" component="div" color="textSecondary" sx={Styles.percentageText}>
                                    {formattedRemainingTime}
                                </Typography>
                                <Typography variant="h6">Remaining</Typography>
                            </Box>
                        </>
                    }

                </Box>

                {view === 'post-sequence' ?
                    <PostSequence postSequence={props.postSequenceAllData} />
                    :
                    <Box sx={{
                        ...(isPortrait ? {
                            width: '65%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                        } : {})
                    }}>
                        <Box sx={{ ...Styles.alignProcess, ...(isPortrait ? { flexWrap: 'wrap', width: '100%', right: '-20px' } : {}) }}>
                            <Grid item xs={6} sx={{ ...Styles.mainPercentageComp, }}>
                                <Box sx={Styles.percentageComponent}>
                                    <Box sx={{ position: 'relative', width: '88%', overflow: 'hidden' }}>
                                        <Typography
                                            variant="h5"
                                            sx={isMarqueeRequired(processValues['process_GaugeCurrentBatchFinalState']?.value)
                                                ? { ...Styles.percentages, ...Styles.marquee }
                                                : Styles.percentages
                                            }
                                        >
                                            {processValues['process_GaugeCurrentBatchFinalState']?.value}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_BatchState']?.text}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                                <Box sx={Styles.percentageComponent}>
                                    <Typography variant="h5" sx={Styles.percentages}>{formatTime(parseFloat(processValues['process_GaugeSeqTimerLen']?.value ?? '0'))}</Typography>
                                    <Typography variant="h6" sx={Styles.percentagesText}>{"Total Time"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                                <Box sx={Styles.percentageComponent}>
                                    <Typography variant="h5" sx={Styles.percentages}>{processValues['process_BatchNumber']?.value}</Typography>
                                    <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_BatchNumber']?.text}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                                <Box sx={Styles.percentageComponent}>
                                    <Typography variant="h5" sx={Styles.percentages}>{processValues['process_BatchRestart']?.value}</Typography>
                                    <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_BatchRestart']?.text}</Typography>
                                </Box>
                            </Grid>
                        </Box>
                        <Button sx={{
                            ...buttonStyles,
                            ...(isPortrait ? Styles.processBtnPortrait : {})
                        }} variant="contained" disabled={processValues['process_GaugeCurrentBatchFinalState']?.value === 'Idle'}>{buttonText}</Button>
                    </Box>
                }
            </Box>
            <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                sx={{
                    ...Styles.toggleBtnStyle,
                    ...(isPortrait ? Styles.toggleBtnPortrait : {})
                }}
            >
                <ToggleButton
                    value="process"
                    data-testid={"process-status"}
                    sx={{
                        color: view === 'process' ? '##000000' : '#ABABAB',
                        width: '50%',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '18px',
                        backgroundColor: view === 'process' ? '#FFF' : 'inherit',
                        '&.Mui-selected': {
                            backgroundColor: '#FFF',
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: '#FFF',
                        },
                    }}
                >
                    Process Status
                </ToggleButton>
                <ToggleButton
                    value="post-sequence"
                    data-testid={"post-sequence"}
                    sx={{
                        color: view === 'post-sequence' ? '##000000' : '#ABABAB',
                        width: '50%',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '18px',
                        backgroundColor: view === 'post-sequence' ? '#FFF' : 'inherit',
                        '&.Mui-selected': {
                            backgroundColor: '#FFF',
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: '#FFF',
                        },
                    }}
                >
                    Post Sequence
                </ToggleButton>
            </ToggleButtonGroup>
        </Paper >
    );
};

export default ProcessStep;
