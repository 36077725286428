import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme({
    typography: {
        fontFamily: ['Poppins'].join(',')
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 768,
            lg: 1025,
            xl: 1366,
        },
    },
})

export { baseTheme };