import React from 'react';
import styled from 'styled-components';
import { style } from '../../Utils/styles';

interface ProgressBarProps {
    segments: number;
    value: number | string;  // Value out of 100
    radius: number;
    strokeWidth: number;
    spaceBetween: number;
    labels: string[];  // Array of labels for each segment
    colors: string[];  // Array of colors for each segment
    total: number;
}

const StyledSvg = styled.svg`
    overflow: visible;
`;

const SegmentedProgressBar: React.FC<ProgressBarProps> = ({
    segments,
    value,
    radius,
    strokeWidth,
    spaceBetween,
    labels,
    colors,
    total
}) => {
    // Calculate the number of filled segments based on the value
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    const totalLength = 2 * Math.PI * radius;
    const segmentLength = (totalLength - spaceBetween * segments) / segments;
    const filledLength = (numericValue / total) * totalLength;

    const renderSegments = () => {
        const segmentsArray = [];
        let remainingLength = filledLength;

        for (let i = 0; i < segments; i++) {
            const startOffset = i * (segmentLength + spaceBetween);
            const currentSegmentLength = Math.min(remainingLength, segmentLength);
            remainingLength -= currentSegmentLength;

            // Filled segment
            segmentsArray.push(
                <circle
                    key={`filled-${i}`}
                    cx="50%"
                    cy="50%"
                    r={radius}
                    fill="transparent"
                    stroke={colors[i] || style.palette.progressBar.main}  // Use color from colors array
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${currentSegmentLength} ${totalLength - currentSegmentLength}`}
                    strokeDashoffset={-startOffset}
                    style={{
                        transform: 'rotate(-90deg)',
                        transformOrigin: '50% 50%',
                    }}
                />
            );

            // Unfilled segment
            if (currentSegmentLength < segmentLength) {
                segmentsArray.push(
                    <circle
                        key={`unfilled-${i}`}
                        cx="50%"
                        cy="50%"
                        r={radius}
                        fill="transparent"
                        stroke={style.palette.progressBar.bgColor}
                        strokeWidth={strokeWidth}
                        strokeDasharray={`${segmentLength - currentSegmentLength} ${totalLength - (segmentLength - currentSegmentLength)}`}
                        strokeDashoffset={-startOffset - currentSegmentLength}
                        style={{
                            transform: 'rotate(-90deg)',
                            transformOrigin: '50% 50%',
                        }}
                    />
                );
            }
            // Label
            const angle = (startOffset + segmentLength / 2) / radius - Math.PI / 2;
            const textX = radius + (radius + strokeWidth * 2 + 10) * Math.cos(angle) + strokeWidth;
            const textY = radius + (radius + strokeWidth * 2 + 10) * Math.sin(angle) + strokeWidth;
            segmentsArray.push(
                <text
                    key={`label-${i}`}
                    x={textX}
                    y={textY}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="14px"
                    fill={style.palette.black[500]}
                    fontFamily="Poppins"
                    fontWeight="500"
                >
                    {labels[i]}
                </text >
            );
        }
        return segmentsArray;
    };

    return (
        <StyledSvg
            width="250px"
            height="250px"
            viewBox={`0 0 ${radius * 2 + strokeWidth * 2} ${radius * 2 + strokeWidth * 2}`}
        >
            {renderSegments()}
        </StyledSvg>
    );
};

export default SegmentedProgressBar;
