import React, { useEffect, useState } from 'react';
import './ImageBox.css'; // Import CSS file for styling
import { callApi } from '../../Services/Api';
import { Box, Typography } from '@mui/material';
import { style, Styles } from '../../Utils/styles';
import { styled } from '@mui/system';

interface ImageContent {
    id: string;
    content: string;
    pos_x: string;
    pos_y: string;
    width: string;
    height: string;
    z_index: string;
    animate: string;
    type: string;
    value: string;
}

interface ImageBoxProps {
    pumpGasData: PumpGasAllData[];
}

interface PumpGasAllData {
    id: string;
    unit: string;
    value: string;
    display_format: string;
    display_unit: string;
    type: string;
    text: string;
    width: string;
    height: string;
    animate: string;
    image: string;
    pos_x: string;
    pos_y: string;
    z_index: string;
}
const DataBox = styled(Box)({
    backgroundColor: style.palette.BgColor,
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '4px',
    textAlign: 'center',
    position: 'absolute'
});
const ImageBox: React.FC<ImageBoxProps> = ({ pumpGasData }) => {
    const [imageContents, setImageContents] = useState<ImageContent[]>([]);
    const [containerWidth, setContainerWidth] = useState<string>('1038');
    const [containerHeight, setContainerHeight] = useState<string>('600');

    useEffect(() => {
        const fetchImageContent = async (imageUrl: string, item: PumpGasAllData) => {
            const { id, ...rest } = item; // Destructure to exclude id from rest
            if (!imageUrl) {
                return { id, content: '', ...rest };
            }
            const headerTxt = imageUrl.includes('INB') ? 'image/apng' : 'application/xml';

            try {
                const eventsPayload = {
                    endpoint: imageUrl,
                    method: "GET",
                    headers: {
                        'Accept': headerTxt,
                    }
                };
                const eventsResponse = await callApi(eventsPayload);
                return { id, content: eventsResponse, ...rest };
            } catch (error) {
                console.error("Error fetching image:", error);
                return { id, content: '', ...rest };
            }
        };

        const fetchAllImages = async () => {
            const results = await Promise.all(
                pumpGasData
                    .filter(item => item.type === 'image' || item.type === 'icon')
                    .map(async item => {
                        const result = await fetchImageContent(item.image, item);
                        // Update container dimensions if type is 'image'
                        if (item.type === 'image') {
                            setContainerWidth(item.width);
                            setContainerHeight(item.height)
                        }
                        return result;
                    })
            );
            setImageContents(results);
        };

        fetchAllImages();
    }, [pumpGasData]);
    const formatValue = (value: string, displayFormat: string) => {
        const numericValue = parseFloat(value);
        if (displayFormat !== undefined) {
            if (displayFormat.includes('%.0f')) {
                return numericValue.toFixed(0);
            } else if (displayFormat.includes('%.1f')) {
                return numericValue.toFixed(1);
            } else if (displayFormat.includes('%.2f')) {
                return numericValue.toFixed(2);
            } else if (displayFormat.includes('%.2e')) {
                return numericValue.toExponential(2);
            }
        }
        return numericValue.toString();
    };
    return (
        <div className="image-container"
            style={{ width: `${containerWidth}px`, height: `${containerHeight}px` }}>
            {imageContents.map(imageContent => {
                const isAnimatedIcon = imageContent.type === 'icon' && Number(imageContent.value) > 0;
                const classNames = `${imageContent.type === 'icon' ? 'icon' : ''} ${isAnimatedIcon ? 'animated-box' : ''}`;
                return imageContent.content.startsWith('<?xml') || imageContent.content.startsWith('<svg') ? (
                    <div className={classNames}
                        style={{
                            position: 'absolute',
                            left: `${imageContent.pos_x}px`,
                            top: `${imageContent.pos_y}px`,
                            width: `${imageContent.width}px`,
                            height: `${imageContent.height}px`,
                            zIndex: imageContent.z_index
                        }}
                        key={imageContent.id}
                        data-testid={imageContent.id}
                        dangerouslySetInnerHTML={{ __html: imageContent.content }}
                    />
                ) : (
                    <img
                        className={classNames}
                        style={{
                            position: 'absolute',
                            left: `${imageContent.pos_x}px`,
                            top: `${imageContent.pos_y}px`,
                            width: `${imageContent.width}px`,
                            height: `${imageContent.height}px`,
                            zIndex: imageContent.z_index
                        }}
                        key={imageContent.id}
                        data-testid={imageContent.id}
                        src={imageContent.content}
                        alt={imageContent.id}
                    />
                );
            })}
            {imageContents.length > 0 && pumpGasData.filter((item) => item.type === "label").map((control) => {
                const formattedValue = formatValue(control.value, control.display_format);
                return (
                    <DataBox
                        key={control.id}
                        sx={{
                            ...Styles.bottomLabel,
                            left: `${control.pos_x}px`,
                            top: `${control.pos_y}px`,
                            zIndex: control.z_index
                        }}
                        data-testid={control.id}
                    >
                        <Typography variant="body1" sx={Styles.value}>
                            {formattedValue + " " + control.display_unit}
                        </Typography>
                    </DataBox>
                );
            })}
        </div>
    );
}

export default ImageBox;
