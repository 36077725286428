import { createTheme, styled } from '@mui/material/styles';
import { baseTheme } from './base';
import { ITheme } from './styleInterface';

const theme = createTheme(baseTheme, {
  palette: {
    green: {
      50: '#E6F2F0',
      100: '#B0D6D1',
      200: '#8AC2BA',
      300: '#54A69B',
      400: '#339588',
      500: '#007A6A',
      600: '#006F60',
      700: '#00574B',
      800: '#00433A',
      900: '#00332D',
    },
    black: {
      50: '#E6E6E6',
      100: '#B0B0B0',
      200: '#8A8A8A',
      300: '#545454',
      400: '#333333',
      500: '#000000',
      600: '#000000',
      700: '#000000',
      800: '#000000',
      900: '#000000',
    },
    error: {
      bgColor: '#FF1C1C30',
      mainColor: '#eb0202',
      textColor: '#FF1C1C',
    },
    warning: {
      bgColor: '#e3df165c',
      mainColor: '#daa416',
    },
    information: {
      bgColor: '#81818130',
      mainColor: '#818181',
    },
    activeBtn: {
      borderColor: '#EB0000',
      bgColor: '#EB000024',
      textColor: '#EB0000',
    },
    completeBox: {
      bgColor: '#41BA232B',
      textColor: '#2DA110',
    },
    progressBar: {
      main: '#24A3CA',
      bgColor: '#e0e0e0',
    },
    logoColor: {
      main: '#eb0000',
    },
    Neutral: '#FFFFFF',
    Primary: '#007A6A',
    TitleColor: '#36404D',
    BtnBgColor: '#F9F9F9',
    HeaderBgColor: '#339588',
    ContentBgColor: '#F2F2F2',
    GrayStroke: '#E1E1E1',
    ActiveBgColor: '#F6FAF9',
    PurpleStroke: '#531BB5',
    BgColor: '#F5F5F5',
    AppointmentBgColor: '#F9F8FC',
    CellDataNotFoundColor: '#FF0000',
    systemBgColor: '#E6E6E629',

    Red: '#FF0909',
    LightGreen: 'Green',
    Orange: '#F49600',
    Yellow: '#FECF16',
    Blue: '#2C88D9',
    DarkBlue: '#153351',
    Purple: '#531BB5',

    BlueBgColor: '#2C88D9',
    GreenBgColor: '#007A6A',
    Purple50Stroke: '#531BB58',
    DeliveryBgColor: '#007A6A26',
    LoadingBgColor: '#2C88D926',
    UnloadingBgColor: '#F4960026',
    ReceiptBgColor: '#531BB526',
    Purple50BgColor: '#531BB526',
    ViewIconBGColor: '#2C88D915',
    EditIconBGColor: '#007A6A15',
    eventBgColor: '#531BB503',
    unloadingEventBgColor: '#F4960015',
    RecieptEventBgColor: '#531BB515',

    gradient: {
      GreenLInearStroke:
        'linear-gradient(90deg, #ADFDF2 -4.99%,#007A6A 107.44%)',
      PurpleLinearColor:
        'linear-gradient(180deg, #531BB5 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)',
      OrangeLinearColor:
        'linear-gradient(180deg, #F6A417 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)',
      GreenLinearColor:
        'linear-gradient(180deg, #007A6A -15.4%, rgba(255, 255, 255, 0.00) 107.87%)',
      YellowLinearColor:
        'linear-gradient(180deg, #FECF16 -15.4%, rgba(255, 255, 255, 0.00) 107.87%)',
    },
  },
  typography: {
    h1: {},
    h2: {
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    h5: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    h6: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    subtitle1: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    subtitle2: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    body1: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: 'normal',
      textAlign: 'left',
    },
    body2: {
      fontFamily: 'Poppins',
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    button: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'capitalize',
    },
    caption: {
      fontFamily: 'Poppins',
      fontSize: '8px',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    overline: {},
    subHeading2: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',
    },
  },
}) as unknown as ITheme;

export { theme, theme as style };

// component-specific styles

export const PREFIX = 'App';
export const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  largeAvatar: `${PREFIX}-largeAvatar`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    margin: '16px',
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '12px',
    cursor: 'pointer',
  },
  [`& .${classes.largeAvatar}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export const Styles = {
  //Process page
  ProcessPortraitStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  mainContainer: {
    maxWidth: '100% !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  AlignLeft: {
    textAlign: 'left',
  },
  OverAllStatus: {
    marginBottom: '20px',
    fontSize: '24px',
    color: theme.palette.black[500],
  },
  alertHeader: {
    textAlign: 'left',
    marginBottom: '10px',
    fontSize: '20px',
    color: theme.palette.black[500],
  },
  toggleBtnStyle: {
    marginBottom: 2,
    padding: '5px',
    marginTop: '20px',
    backgroundColor: '#EFEFEF',
    width: '95%',
    borderRadius: '8px',
  },
  toggleBtnPortrait: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '50%',
    transform: 'unset',
    left: 'inherit',
    bottom: 'inherit',
  },
  processBtnPortrait: {
    width: '250px',
  },
  //pumpGas page
  bottomLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: 'max-content',
    borderRadius: '2px',
    backgroundColor: '#e1e3e6',
    border: '1px solid #00000066',
    padding: '1px 8px',
  },
  mainHeading: {
    fontSize: '24px',
    alignSelf: 'flex-start',
    marginLeft: '20px',
    marginBottom: '0',
    marginTop: '10px',
  },
  label: {
    fontSize: '16px',
  },
  value: {
    fontSize: '10px',
    wordBreak: 'break-all',
  },
  //alarm page
  alarmComponent: {
    margin: 2,
    '& .MuiPaper-elevation': {
      borderRadius: '12px',
    },
  },
  alarmHeading: {
    padding: '16px',
    textAlign: 'left',
    fontSize: '24px',
    borderBottom: `1px solid ${theme.palette.black[50]}`,
  },
  bottomAlarm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.black[50]}`,
  },
  bottomBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  bottomText: {
    fontSize: '16px',
    marginLeft: '15px',
  },
  rightArrowStyle: {
    height: '20px',
  },
  //alarmTable page
  alarmsMainComponent: {
    '&::-webkit-scrollbar': {
      height: '6px !important',
      backgroundColor: `${theme.palette.BgColor}!important`,
      borderRadius: '10px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px !important',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  tableHeader: {
    fontSize: '16px',
    color: theme.palette.black[300],
  },
  tableDescription: {
    fontSize: '18px',
    color: theme.palette.black[400],
    border: 'none',
  },
  alarmTable: {
    width: '100%',
  },
  state: {
    backgroundColor: theme.palette.error.bgColor,
    padding: '6px 30px',
    fontSize: '16px',
    color: theme.palette.error.textColor,
    textAlign: 'center',
    borderRadius: '8px',
    textTransform: 'capitalize',
  },
  iconDescription: {
    position: 'relative',
  },
  iconDiv: {
    position: 'absolute' as const,
    left: '21px',
    top: '19px',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  icon: {
    height: '22px',
    width: '22px',
  },
  error: {
    backgroundColor: theme.palette.error.bgColor,
    color: theme.palette.error.mainColor,
  },
  warning: {
    backgroundColor: theme.palette.warning.bgColor,
    color: theme.palette.warning.mainColor,
  },
  information: {
    backgroundColor: theme.palette.information.bgColor,
    color: theme.palette.information.mainColor,
  },
  //systemstatus page
  systemIcon: {
    height: '48px',
    width: '48px',
  },
  CompleteBox: {
    backgroundColor: theme.palette.completeBox.bgColor,
    color: theme.palette.completeBox.textColor,
    fontSize: '12px',
    height: '32px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    '@media (max-width: 1185px) and (min-width: 1024px)': {
      // width: '90px',
    },
  },
  InprocessBox: {
    backgroundColor: theme.palette.error.bgColor,
    color: theme.palette.error.textColor,
    fontSize: '12px',
    height: '32px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    '@media (max-width: 1185px) and (min-width: 1024px)': {
      // width: '90px',
    },
  },
  systemBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: theme.palette.systemBgColor,
    border: `1px solid ${theme.palette.black[50]}`,
    borderRadius: '16px',
    boxShadow: 'none',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.black[400],
  },
  successStyle: {},
  rejectStyle: {
    boxShadow: `inset 0px 0px 6px 2px ${theme.palette.error.mainColor}`,
  },
  SystemButton: {
    // position: 'absolute',
    // left: '50%',
    // bottom: '0',
    // transform: 'translate(-50%, -50%)',
    width: '85%',
    padding: '10px',
    borderRadius: '8px',
    marginTop: '20px',
    fontSize: '16px',
    // backgroundColor: theme.palette.logoColor.main,
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.logoColor.main,
    },
  },
  systemIconBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    width: '100%',
  },
  //processStep page
  subHeader: {
    textAlign: 'left',
    fontSize: '20px',
    color: theme.palette.black[500],
  },
  subHeader2: {
    color: theme.palette.black[400],
  },
  active: {
    backgroundColor: '#DCF1D7',
  },
  BoldText: {
    fontSize: '32px',
    color: theme.palette.black[500],
  },
  percentageText: {
    fontSize: '26px',
    color: theme.palette.black[500],
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
  },
  alignProcess: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '45px',
    // marginBottom: '20px',
    position: 'relative',
  },
  mainPercentageComp: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
  },
  percentageComponent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '115px',
    width: '100%',
    backgroundColor: '#E6E6E629',
    border: '1px solid #E6E6E6',
    borderRadius: '8px',
  },
  rowColumnOne: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
    borderRight: '1px dashed rgba(0, 0, 0, 0.12)',
  },
  rowTop: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.12)',
  },
  rowBottom: {
    borderRight: '1px dashed rgba(0, 0, 0, 0.12)',
  },
  percentages: {
    fontSize: '30px',
    color: theme.palette.black[500],
  },
  percentagesText: {
    fontSize: '18px',
    color: theme.palette.black[400],
  },
  verticalDivider: {
    position: 'absolute',
    height: '225px',
    top: '8px',
    borderStyle: 'dashed',
  },
  horizontalDivider: {
    position: 'absolute',
    width: '25vw',
    height: '1px',
    top: '113px',
    borderRadius: 1,
    borderStyle: 'dashed',
    '@media (max-width: 1024px)': {
      width: '70vw',
    },
  },
  marginTop: {
    marginTop: '25px',
  },
  marginBottom: {
    marginBottom: '25px',
  },
  progressBox: {
    position: 'relative',
    display: 'inline-flex',
    margin: '30px 0',
  },
  processSelection: {
    '@media (max-width: 800px)': {
      width: '75px',
    },
  },
  marquee: {
    display: 'block',
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    animation: 'marquee 9s linear infinite',
  },
  marqueeHeader: {
    display: 'block',
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    animation: 'marquee 15s linear infinite',
  },
  //postsequence page
  postProcess: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '45px',
    marginBottom: '20px',
  },
  mainPostProcess: {
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${theme.palette.black[50]}`,
    backgroundColor: theme.palette.systemBgColor,
    // maxWidth: '45% !important',
    padding: '24px',
    borderRadius: '12px',
  },
  postProcessDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
  },
  //alertsTable page
  alertsMainComponent: {
    overflowX: 'auto',
    height: '498px',
    '&::-webkit-scrollbar': {
      height: '6px !important',
      backgroundColor: `${theme.palette.BgColor}!important`,
      borderRadius: '10px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px !important',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  alertTable: {
    marginTop: '5px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  alertText: {
    fontSize: '14px',
    border: 'none',
  },
  critical: {
    backgroundColor: theme.palette.error.bgColor,
    color: theme.palette.error.textColor,
    textTransform: 'capitalize',
  },
  noAlertsText: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  //header page
  root: {
    header: {
      background: theme.palette.Neutral,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 16px',
      '&.MuiSelect-root': {
        '&:hover': {
          borderColor: theme.palette.green[200], // Hide border on hover
        },
      },

      div: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& .MuiStack-root': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: '8px',
          gap: '10px',

          '& .MuiTypography-subtitle1': {
            color: theme.palette.Neutral,
            wordWrap: 'no-wrap',
          },

          '& .MuiTypography-body1': {
            color: theme.palette.black[300],
          },
        },
        '& .MuiSelect-outlined': {
          '& .MuiTypography-subtitle1': {
            color: theme.palette.Neutral,
            wordWrap: 'no-wrap',
          },
        },
        '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
          color: theme.palette.Neutral,
        },
        '& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon': {
          color: theme.palette.Neutral,
        },
        '& .css-1dlx53a-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 'none',
            '&:hover': {
              borderColor: theme.palette.green[200],
            },
          },
      },
      '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      },
      '& .css-1dlx53a-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          border: 'none',
          '&:hover': {
            borderColor: theme.palette.green[200],
          },
        },
    },
    '& .MuiFormControl-root': {
      border: 'none',
      borderRadius: '4px',
      marginRight: '28px',
      width: '133px',
      img: {
        marginRight: '20px',
      },
      '&:hover': {
        border: 'none',
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.green[400],
      padding: '0px 12px',
      border: 'none',
    },
  },
  headerRight: {
    borderLeft: `1px solid ${theme.palette.black[50]}`,
    img: {
      marginRight: '28px',
    },
  },
  select: {
    border: 'none',
    '&:hover': {
      borderColor: 'transparent',
    },
    '&.MuiSelect-root': {
      '&:hover': {
        borderColor: 'transparent', // Hide border on hover
      },
    },
  },
  selectLang: {
    img: {
      marginRight: '20px',
    },
  },
  roleProfile: {
    background: theme.palette.BgColor,
    marginLeft: '16px',
    padding: '12px 8px 12px 8px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .css-nen11g-MuiStack-root': {
      alignItems: 'center',
    },
    position: 'relative',
  },
  distributerProfile: {
    padding: '5px 33px 5px 12px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    img: {
      marginRight: '8px',
    },
    '@media (max-width: 835px)': {
      padding: '5px 5px 5px 5px',
    },
  },
  activeBtn: {
    border: `1px solid ${theme.palette.activeBtn.borderColor}`,
    background: theme.palette.activeBtn.bgColor,
    '& .MuiTypography-root': {
      color: `${theme.palette.activeBtn.textColor}!important`,
    },
  },
  navBtns: {
    fontSize: '14px',
  },
  logoIcon: {
    margin: '0 20px',
    cursor: 'pointer',
  },
  mobileMenuButton: {
    display: 'none',
    '@media (max-width: 800px)': {
      display: 'block',
    },
  },
  desktopMenu: {
    display: 'flex',
    '@media (max-width: 800px)': {
      display: 'none !important',
    },
  },
  rightBox: {
    position: 'absolute' as const,
    right: 0 as const,
  },
  coaterStyle: {
    borderRadius: '8px',
    padding: '10px 20px',
  },
  coaterText: {
    color: theme.palette.logoColor.main,
    fontSize: '35px',
    fontWeight: 600,
  },
};
