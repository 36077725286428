import React, { useState } from 'react';
import { Paper, Typography, Pagination, Grid, Box, useMediaQuery } from '@mui/material';
import CustomPaginationItem from '../../Component/Pagination/CustomPaginationItem';
import { IMAGES } from '../../asset';
import CircularIndeterminate from '../../Component/Progress/CircularProgress';
import AlarmTable from './AlarmTable';
import { Styles } from '../../Utils/styles';

interface AlarmData {
  messageData: Alarm[],
  loading: boolean;
}
interface Alarm {
  datetime: string | null;
  category: string | null;
  id: number;
  code: string | null;
  group: string;
  type: string;
  level: string;
  text: string;
}
const Alarms: React.FC<AlarmData> = ({ messageData, loading }) => {
  const [page, setPage] = useState<number>(1);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const rowsPerPage = isPortrait ? 18 : 12;
  const pageCount = Math.ceil(messageData.length / rowsPerPage);
  const previousComponent = () => <span>Prev</span>;
  const nextComponent = () => <span>Next</span>;
  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };
  return (
    <Grid item sm={12} sx={Styles.alarmComponent} data-testid="AlarmsComponent">
      <Paper>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px" data-testid="loading">
            <CircularIndeterminate />
          </Box>
        ) : messageData.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <Typography variant="h6">No data found</Typography>
          </Box>
        ) : (
          <AlarmTable messageData={messageData} rowsPerPage={rowsPerPage} page={page} />
        )}
        <Grid sx={Styles.bottomAlarm}>
          <Box sx={Styles.bottomBox}>
            <Typography sx={Styles.bottomText}>
              {`Showing ${isPortrait ? 18 : 12} Entries`}
            </Typography>
            <img src={IMAGES.rightArrow} alt="arrow" style={Styles.rightArrowStyle} />
          </Box>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <CustomPaginationItem
                {...item}
                components={{ previous: previousComponent, next: nextComponent }}
              />
            )}
            style={{ padding: '16px', display: 'flex', justifyContent: 'flex-end' }}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Alarms;