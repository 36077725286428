import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { IMAGES } from '../../asset';
import { Styles } from '../../Utils/styles';

type AlarmLevel = 'error' | 'warning' | 'information';

interface AlarmData {
    messageData: Alarm[],
    rowsPerPage: number,
    page: number
}

interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

const AlarmTable: React.FC<AlarmData> = ({ messageData, rowsPerPage, page }) => {
    // Sort alarms by datetime in descending order
    const sortedAlarms = [...messageData].sort((a, b) => {
        const dateA = a.datetime ? new Date(a.datetime).getTime() : 0;
        const dateB = b.datetime ? new Date(b.datetime).getTime() : 0;
        return dateB - dateA;
    });
    const formatDate = (dateString: string | null): string => {
        if (!dateString) return "null";
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    return (
        <TableContainer sx={Styles.alarmsMainComponent} data-testid="Alarm-table">
            <Table sx={Styles.alarmTable}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={Styles.tableHeader}>Date & Time</TableCell>
                        <TableCell sx={Styles.tableHeader}>State</TableCell>
                        <TableCell sx={Styles.tableHeader}>Description</TableCell>
                        <TableCell sx={Styles.tableHeader}>Id</TableCell>
                        <TableCell sx={Styles.tableHeader}>Part</TableCell>
                        {/* <TableCell sx={Styles.tableHeader}>Group</TableCell>
                        <TableCell sx={Styles.tableHeader}>Type</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedAlarms.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((alarm) => {
                        const level = alarm?.level as AlarmLevel;
                        return (
                            <TableRow key={alarm?.id} sx={{ verticalAlign: 'baseline' }} data-testid="alarm-row">
                                <TableCell sx={Styles.tableDescription}>{formatDate(alarm?.datetime)}</TableCell>
                                <TableCell sx={{ ...Styles.tableDescription, position: 'relative' }}>
                                    <Typography sx={{ ...Styles.state, ...Styles[level], width: '120px' }}>
                                        <div style={{ ...Styles.iconDiv }}>
                                            <img src={
                                                alarm?.level === "error"
                                                    ? IMAGES.errorIcon
                                                    : alarm?.level === "warning"
                                                        ? IMAGES.warningIcon
                                                        : IMAGES.informationIcon
                                            } alt="alert-alarm" style={Styles.icon} />
                                        </div>
                                        {alarm?.level}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ ...Styles.tableDescription, ...Styles.iconDescription }}>
                                    {alarm?.text}
                                </TableCell>
                                <TableCell sx={Styles.tableDescription}>{alarm?.code}</TableCell>
                                <TableCell sx={Styles.tableDescription}>{alarm?.category}</TableCell>
                                {/* <TableCell sx={Styles.tableDescription}>{alarm?.group ? alarm?.group : '-'}</TableCell>
                                <TableCell sx={Styles.tableDescription}>{alarm?.type}</TableCell> */}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AlarmTable;
