import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { classes, style, Styles } from '../../Utils/styles';
import SegmentedProgressBar from '../Progress/SegmentedProgressBar';

interface ProcessStepProps {
    labels: string[];
    processAllData: ProcessAllData[];
}

interface ProcessAllData {
    id: string;
    unit: string;
    value: string;
    text: string;
    value2: string;
}
interface ProcessValues {
    [key: string]: { value: string, text: string };
}
const ProcessStep2: React.FC<ProcessStepProps> = (props) => {
    const [processValues, setProcessValues] = useState<ProcessValues>({});

    useEffect(() => {
        // Function to extract values and texts based on keys
        const extractValues = (data: ProcessAllData[], keys: string[]): ProcessValues => {
            const result: ProcessValues = {};
            keys.forEach(key => {
                const item = data.find(obj => obj.id === key);
                if (item) {
                    result[key] = { value: item.value, text: item.text };
                }
            });
            return result;
        };

        // Extract processValues whenever processAllData changes
        const keysToExtractINB = [
            "process_ChargeNumber",
            "process_ChargeStatus",
            "process_RecipeName",
            "process_RecipeTime",
            "process_RecipePhase",
            "process_RecipeSpcPercent",
            "process_RecipeStep",
            "process_RecipeSpcPhasePercent"
        ];

        const updatedValues = extractValues(props.processAllData, keysToExtractINB);
        setProcessValues(updatedValues);
    }, [props.processAllData]);

    // Determine button state and styles
    const isButtonDisabled = processValues['process_RecipePhase']?.value === 'Wait for start';
    const buttonText = isButtonDisabled ? 'Start cycle' : 'Stop cycle';
    const buttonStyles = {
        ...Styles.SystemButton,
        backgroundColor: isButtonDisabled ? style.palette.progressBar.main : style.palette.logoColor.main,
    };
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const isMarqueeRequired = (text: string) => {
        const threshold = isPortrait ? 15 : 10;
        return text?.length > threshold;
    };
    const isMarqueeRequiredHead = (text: string) => {
        const threshold = 40;
        return text?.length > threshold;
    };
    return (
        <Paper className={classes.paper} sx={{ height: '840px', overflowX: 'auto', position: 'relative', ...(isPortrait ? { height: 'inherit' } : {}) }} data-testid="process-step">
            <Typography variant="h6" sx={Styles.AlignLeft}>Process Status</Typography>
            <Typography variant="h6" sx={isMarqueeRequiredHead(processValues['process_RecipeName']?.value)
                ? { ...Styles.subHeader, ...Styles.marqueeHeader }
                : Styles.subHeader
            }>
                {processValues['process_RecipeName']?.value}
            </Typography>
            <Box sx={{
                ...(isPortrait ? Styles.ProcessPortraitStyle : {})
            }}>
                <Box sx={{
                    ...Styles.progressBox,
                    ...(isPortrait ? { marginLeft: '55px' } : {})
                }}>
                    <SegmentedProgressBar
                        segments={1}
                        value={processValues['process_RecipeSpcPercent']?.value}
                        total={100}
                        radius={100}
                        strokeWidth={20}
                        spaceBetween={1}
                        labels={[]}
                        colors={[]}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" component="div" color="textSecondary" sx={Styles.percentageText}>
                            {`${Math.round(Number(processValues['process_RecipeSpcPercent']?.value))}%`}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    ...(isPortrait ? {
                        width: '65%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    } : {})
                }}>
                    <Box sx={{ ...Styles.alignProcess, ...(isPortrait ? { flexWrap: 'nowrap', width: '100%', } : {}) }}>
                        <Grid item xs={6} sx={{ ...Styles.mainPercentageComp, }}>
                            <Box sx={Styles.percentageComponent}>
                                <Typography variant="h5" sx={Styles.percentages}>{processValues['process_ChargeStatus']?.value}</Typography>
                                <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_ChargeStatus']?.text}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                            <Box sx={Styles.percentageComponent}>
                                <Typography variant="h5" sx={Styles.percentages}>{processValues['process_ChargeNumber']?.value}</Typography>
                                <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_ChargeNumber']?.text}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                            <Box sx={{ ...Styles.percentageComponent, overflow: 'hidden' }}>
                                <Typography variant="h5" sx={isMarqueeRequired(processValues['process_RecipePhase']?.value)
                                    ? { ...Styles.percentages, ...Styles.marquee }
                                    : Styles.percentages
                                }>{processValues['process_RecipePhase']?.value}</Typography>
                                <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_RecipePhase']?.text}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ ...Styles.mainPercentageComp }}>
                            <Box sx={Styles.percentageComponent}>
                                <Typography variant="h5" sx={Styles.percentages}>{processValues['process_RecipeStep']?.value}</Typography>
                                <Typography variant="h6" sx={Styles.percentagesText}>{processValues['process_RecipeStep']?.text}</Typography>
                            </Box>
                        </Grid>
                    </Box>
                    <Button sx={{
                        ...buttonStyles,
                        ...(isPortrait ? Styles.processBtnPortrait : {})
                    }} variant="contained" >{buttonText}</Button>
                </Box>
            </Box>


        </Paper>
    );
};

export default ProcessStep2;
