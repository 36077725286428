interface ApiRequest {
    endpoint: string;
    method: string;
    data?: object;
    headers?: HeadersInit;
}

const baseUrl = 'https://stoplight.io/mocks/oerlikon/web-hmi-preproject:v2/668748'


export const callApi = async ({ endpoint, method, data: payload, headers }: ApiRequest) => {
    const requestHeaders = {
        'Content-Type': 'application/json',
        ...headers,
    };

    try {
        const response = await fetch(`${baseUrl}/${endpoint}`, {
            method,
            headers: requestHeaders,
            body: method !== 'GET' ? JSON.stringify(payload) : null,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
        }

        let responseData;
        if (endpoint.includes('__example')) {
            responseData = await response.text();
        } else {
            responseData = await response.json();
        }

        return responseData;
    } catch (error) {
        console.error(`Failed to call API: ${error}`);
        throw error;
    }
};
