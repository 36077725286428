import React, { useEffect, useState } from 'react';
import GaugeComponent from 'react-gauge-component';
import { Typography } from '@mui/material';
import { Styles, theme } from '../../Utils/styles';

interface IGaugeValues {
    labels: string[];
    counter: string;
    total: string;
    timeRemaining: string;
}

const GaugeContainer: React.FC<IGaugeValues> = ({ labels, counter, total, timeRemaining }) => {
    const [gaugeValues, setGaugeValues] = useState<IGaugeValues>({ labels, counter, total, timeRemaining });

    const formatTime = (milliseconds: number): string => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (hours > 0) {
            return `${hours}hr ${minutes}min`;
        } else if (minutes > 0) {
            return `${minutes}min`;
        } else {
            return `${seconds}sec`;
        }
    };

    const calculateCounterPercentage = () => {
        const counterValue = parseFloat(gaugeValues.counter);
        const totalValue = parseFloat(gaugeValues.total);

        if (isNaN(counterValue) || isNaN(totalValue)) return 0;

        const percentage = (counterValue / totalValue) * 100;

        return Math.floor(percentage);
    };

    useEffect(() => {
        setGaugeValues({ labels, counter, total, timeRemaining });
    }, [labels, counter, total, timeRemaining]);

    const generateSubArcs = (labels: string[]) => {
        const colors = ['#66b2ff', '#ff9933', '#ff6666', '#66cc66', '#9966ff'];
        const limits = [20, 40, 60, 80, 100];

        return labels.map((label, index) => ({
            limit: limits[index] || 100,
            color: colors[index % colors.length],
            showTick: true,
            tooltip: {
                text: label
            }
        }));
    };

    const generateTicks = (labels: string[]) => {
        const step = 100 / labels.length;
        return labels.map((label, index) => ({
            value: step * (index),
            text: label
        })).concat({ // Adding the 'End' label
            value: 100,
            text: 'End'
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "400px" }}>
            <GaugeComponent
                type="grafana"
                arc={{
                    width: 0.3,
                    padding: 0.01,
                    cornerRadius: 1,
                    gradient: false,
                    subArcs: generateSubArcs(labels),
                }}
                pointer={{
                    color: '#345243',
                    length: 0.90,
                    width: 10,
                }}
                labels={{
                    valueLabel: { formatTextValue: () => "Pumping", style: { fontSize: "35px", fill: '#000000', fontWeight: 500, textShadow: 'none' } },
                    tickLabels: {
                        type: 'outer',
                        defaultTickValueConfig: {
                            formatTextValue: (value: number) => {
                                const tick = generateTicks(labels).find(t => t.value === value);
                                return tick ? tick.text : value.toString();
                            },
                            style: { fontSize: "13" }
                        },
                        ticks: generateTicks(labels)
                    }
                }}
                value={calculateCounterPercentage()}
                minValue={0}
                maxValue={100}
            />
            <div className='gauge-bottom-values' style={{
                display: "flex", flexDirection: 'column', alignItems: 'center', position: 'relative',
                top: '-10px'
            }}>
                <Typography variant="h6" sx={{ ...Styles.subHeader, fontSize: '24px' }}>{formatTime(parseFloat(gaugeValues.timeRemaining ?? '0'))}</Typography>
                <Typography variant="h6" sx={{ fontSize: '14px', color: theme.palette.black[500], }}>Remaining Time</Typography>

            </div>
        </div>
    );
};

export default GaugeContainer;
