import React, { useEffect, useState } from 'react';
import './App.css';
import '../src/asset/css/style.css';
import { ThemeProvider } from '@mui/material';
import { style, theme } from './Utils/styles';
import Header from './Component/Header/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import { callApi } from './Services/Api';

interface ControlData {
  id: string;
  value_id: string | null;
  text_id: string;
  type: string;
  text: string;
  display_format: string;
  display_unit: string;
  width: string;
  height: string;
  animate: string;
  image: string;
  pos_x: string;
  pos_y: string;
  z_index: string;
}

interface ConfigData {
  views: {
    id: string;
    text: string;
    controls: ControlData[]
  }[];
}

interface Alarm {
  datetime: string | null;
  category: string | null;
  id: number;
  code: string | null;
  group: string;
  type: string;
  level: string;
  text: string;
}

interface ProcessAllData {
  id: string;
  unit: string;
  value: string;
  text: string;
  value2: string;
}

interface PumpGasAllData {
  id: string;
  value_id: string | null;
  type: string;
  text: string;
  display_format: string;
  display_unit: string;
  width: string;
  height: string;
  animate: string;
  image: string;
  pos_x: string;
  pos_y: string;
  z_index: string;
  unit: string;
  value: string;
}

const App = () => {
  const [activeSection, setActiveSection] = useState<string>('Process');
  const [configData, setConfigData] = useState<ConfigData | undefined>(undefined);
  const [controlData, setControlData] = useState<ControlData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [systemStatusObjects, setSystemStatusObjects] = useState<ControlData[]>([]);
  const [processObjects, setProcessObjects] = useState<ControlData[]>([]);
  const [postSequenceObject, setPostSequenceObject] = useState<ControlData[]>([]);
  const [pumpAndGasObject, setPumpAndGasObject] = useState<ControlData[]>([]);
  const [allValueId, setAllValueId] = useState<string[]>([]);
  const [allData, setAllData] = useState<ProcessAllData[]>([]);
  const [processObjectsMainAllData, setProcessObjectsMainAllData] = useState<ProcessAllData[]>([]);
  const [systemStatusMainAllData, setSystemStatusMainAllData] = useState<ProcessAllData[]>([]);
  const [postSequenceMainAllData, setPostSequenceMainAllData] = useState<ProcessAllData[]>([]);
  const [pumpAndGasMainAllData, setPumpAndGasMainAllData] = useState<PumpGasAllData[]>([])
  const [pumpGasData, setPumpGasData] = useState<PumpGasAllData[]>([]);
  const [messageData, setMessageData] = useState<Alarm[]>([]);
  const [machineType, setMachineType] = useState<string>('BAL');

  // Function to fetch the config data
  const fetchConfig = async () => {
    try {
      const eventsPayload = {
        endpoint: "config",
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Prefer': `code=200, example=${machineType}_Config`
        },
      };

      const eventsResponse = await callApi(eventsPayload);
      setConfigData(eventsResponse);
    } catch (error) {
      console.error("Error fetching process details:", error);
      setConfigData(undefined);
    }
  };

  // Extract control data when config data changes
  useEffect(() => {
    if (configData) {
      const extractedData = configData?.views?.flatMap(view =>
        view.controls.map(control => ({
          id: control.id,
          text: control.text,
          value_id: control.value_id,
          text_id: control.text_id,
          type: control.type,
          display_format: control.display_format,
          display_unit: control.display_unit,
          width: control.width,
          height: control.height,
          animate: control.animate,
          image: control.image,
          pos_x: control.pos_x,
          pos_y: control.pos_y,
          z_index: control.z_index
        }))
      );
      setControlData(extractedData);

      // Separate the data based on the id prefix
      const systemStatusMainData: ControlData[] = [];
      const processMainData: ControlData[] = [];
      const postSequenceMainData: ControlData[] = [];
      const pumpAndGasMainData: ControlData[] = [];

      const valueIds = extractedData.map(item => item.value_id).filter(value_id => value_id);

      extractedData.forEach(item => {
        if (item.id.startsWith('systemStatus_')) {
          systemStatusMainData.push(item);
        } else if (item.id.startsWith('process_')) {
          processMainData.push(item);
        } else if (item.id.startsWith('postSeq_')) {
          postSequenceMainData.push(item);
        } else if (item.id.startsWith('pumpAndGas_')) {
          pumpAndGasMainData.push(item);
        }
      });

      // Store the separated data in state
      setSystemStatusObjects(systemStatusMainData);
      setProcessObjects(processMainData);
      setPostSequenceObject(postSequenceMainData);
      setPumpAndGasObject(pumpAndGasMainData);
      setAllValueId(valueIds as string[]);
    }
  }, [configData]);

  // Fetch process data
  const fetchProcessData = async (controlIds: string[]) => {
    try {
      const eventsPayload = {
        endpoint: "values",
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Prefer': `code=200, example=${machineType}_Process`
        },
        data: controlIds
      };

      const eventsResponse = await callApi(eventsPayload);
      setAllData(eventsResponse);
    } catch (error) {
      console.error("Error fetching process details:", error);
      setAllData([]);
    }
  };

  // Fetch pump and gas data
  const fetchPumpGasData = async (controlIds: string[]) => {
    try {
      // Prepare the payload for the API call
      const eventsPayload = {
        endpoint: "values",
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Prefer': `code=200, example=${machineType}_PumpAndGas`
        },
        data: controlIds
      };

      // Make the API call
      const eventsResponse = await callApi(eventsPayload);

      // Update the state with the fetched data
      setPumpGasData(eventsResponse);
    } catch (error) {
      // Handle any errors during the API call
      console.error("Error fetching pump and gas data:", error);
      // Set the pumpGasData state to an empty array in case of an error
      setPumpGasData([]);
    }
  };


  // Fetch message data
  const fetchMessageData = async () => {
    try {
      const eventsPayload = {
        endpoint: "messages",
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Prefer': `code=200,example=${machineType}_Messages`
        }
      };

      const eventsResponse = await callApi(eventsPayload);
      setMessageData(eventsResponse);
    } catch (error) {
      console.error("Error fetching process details:", error);
      setMessageData([]);
    } finally {
      setLoading(false);
    }
  }

  // Filter data based on value_id matching
  useEffect(() => {
    if (allData && allData.length > 0) {
      const filterDataByValueId = (objects: ControlData[], allData: ProcessAllData[]): ProcessAllData[] => {
        return objects.map(item => ({
          ...item,
          value: allData.find(data => data?.id === item?.value_id)?.value || null,
          value2: allData.find(data => data?.id === item?.text_id)?.value || null,
          unit: allData.find(data => data?.id === item?.value_id)?.unit || '',
        })) as ProcessAllData[];
      };

      setProcessObjectsMainAllData(filterDataByValueId(processObjects, allData));
      setSystemStatusMainAllData(filterDataByValueId(systemStatusObjects, allData));
      setPostSequenceMainAllData(filterDataByValueId(postSequenceObject, allData));
      // setPumpAndGasMainAllData(filterDataByValueId(pumpAndGasObject, allData));
    }
  }, [allData, processObjects, systemStatusObjects, postSequenceObject]);
  // Filter data based on value_id matching
  useEffect(() => {
    if (pumpGasData && pumpGasData.length > 0) {
      setPumpAndGasMainAllData((prevState): PumpGasAllData[] => {
        // Create a copy of the previous state
        const newState = [...prevState];

        // Map through pumpAndGasObject to update values from pumpGasData
        const updatedData = pumpAndGasObject.map(item => {
          const newData = pumpGasData.find(data => data?.id === item?.value_id);
          const existingData = newState.find(data => data?.id === item?.value_id);

          return {
            ...item,
            value: newData?.value || existingData?.value || '',
            unit: newData?.unit || existingData?.unit || '',
          };
        });

        // Filter out any undefined items (in case no match was found in newData)
        return updatedData.filter(item => item !== undefined);
      });
    }
  }, [pumpGasData, pumpAndGasObject]);

  // console.log('processObjects', processObjects, 'allData', allData, 'processObjectsMainAllData', processObjectsMainAllData)
  // Call fetchConfig to fetch the initial config data
  useEffect(() => {
    fetchConfig();
  }, [machineType]);

  // Set up interval for periodic data fetching
  useEffect(() => {
    if (allValueId.length > 0) {
      const fetchData = () => {
        if (activeSection === "Process") {
          fetchProcessData(allValueId);
          fetchPumpGasData(allValueId);
        }
        if (activeSection === "Machine") {
          fetchPumpGasData(allValueId);
        }
        fetchMessageData();
      };

      // Fetch initially
      fetchData();

      // Set up interval
      const intervalId = setInterval(fetchData, 5000); // Fetch every 5 seconds

      // Clear interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [allValueId, activeSection, machineType]);

  const labels = ['Pumping', 'Heating', 'Etching', 'Coating', 'Post Seq',];
  // console.log(pumpAndGasObject, pumpAndGasMainAllData)
  return (
    <Router>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Header setActiveSection={setActiveSection} activeSection={activeSection} messageData={messageData} machineType={machineType} configData={configData} />
          <div className="content" style={{ backgroundColor: style.palette.BgColor, marginTop: '125px' }}>
            <AppRouter
              activeSection={activeSection}
              setMachineType={setMachineType}
              machineType={machineType}
              messageData={messageData}
              loading={loading}
              controlData={controlData}
              labels={labels}
              systemStatusMainAllData={systemStatusMainAllData}
              postSequenceMainAllData={postSequenceMainAllData}
              processObjectsMainAllData={processObjectsMainAllData}
              pumpGasData={pumpAndGasMainAllData}
            />
          </div>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
