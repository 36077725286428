import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Process from './Pages/Process/Process';
import PumpGas from './Pages/PumpGas/PumpGas';
import Alarms from './Pages/Alarms/Alarms';

interface ControlData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
}

interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

interface ProcessAllData {
    id: string;
    unit: string;
    value: string;
    text: string;
    value2: string;
}

interface PumpGasAllData {
    id: string;
    value_id: string | null;
    type: string;
    text: string;
    display_format: string;
    display_unit: string;
    width: string;
    height: string;
    animate: string;
    image: string;
    pos_x: string;
    pos_y: string;
    z_index: string;
    unit: string;
    value: string;
}
interface AppRouterProps {
    activeSection: string;
    setMachineType: (type: string) => void;
    machineType: string;
    messageData: Alarm[];
    loading: boolean;
    controlData: ControlData[];
    labels: string[];
    systemStatusMainAllData: ProcessAllData[];
    postSequenceMainAllData: ProcessAllData[];
    processObjectsMainAllData: ProcessAllData[];
    pumpGasData: PumpGasAllData[];
}

const AppRouter: React.FC<AppRouterProps> = ({
    activeSection,
    setMachineType,
    machineType,
    messageData,
    loading,
    controlData,
    labels,
    systemStatusMainAllData,
    postSequenceMainAllData,
    processObjectsMainAllData,
    pumpGasData
}) => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/BAL') {
            setMachineType('BAL');
        } else if (location.pathname === '/INB') {
            setMachineType('INB');
        }
    }, [location.pathname, setMachineType]);

    const renderContent = () => {
        switch (activeSection) {
            case 'Process':
                return <Process messageData={messageData} loading={loading} controlData={controlData} labels={labels} systemStatusMainAllData={systemStatusMainAllData} postSequenceMainAllData={postSequenceMainAllData} processObjectsMainAllData={processObjectsMainAllData} machineType={machineType} pumpGasData={pumpGasData} />;
            case 'Machine':
                return <PumpGas messageData={messageData} loading={loading} pumpGasData={pumpGasData} activeSection={activeSection} />;
            case 'alarms':
                return <Alarms messageData={messageData} loading={loading} />;
            default:
                return <Process messageData={messageData} loading={loading} controlData={controlData} labels={labels} systemStatusMainAllData={systemStatusMainAllData} postSequenceMainAllData={postSequenceMainAllData} processObjectsMainAllData={processObjectsMainAllData} machineType={machineType} pumpGasData={pumpGasData} />;
        }
    };

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/BAL" />} />
            <Route path="/BAL" element={renderContent()} />
            <Route path="/INB" element={renderContent()} />
            <Route path="/Gauge" element={renderContent()} />
        </Routes>
    );
};

export default AppRouter;
