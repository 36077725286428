const IMAGES: { [key: string]: string } = {
    LoginImage: require('./images/login-img.png'),
    process: require('./images/process.png'),
    processActive: require('./images/process-active.png'),
    oerlikonLogo: require('./images/oerlikon-logo.png'),
    pump: require('./images/pump.png'),
    pumpActive: require('./images/pump-active.png'),
    alarm: require('./images/alarm.png'),
    alarmActive: require('./images/alarm-active.png'),
    profile: require('./images/profile.png'),
    doorClosed: require('./images/doorClose.png'),
    leakRates: require('./images/leakRates.png'),
    processWater: require('./images/processWater.png'),
    contamination: require('./images/contamination.png'),
    alertsIcon: require('./images/alertsIcon.png'),
    pumpGas: require('./images/pumpGas.png'),
    pumpAlarm: require('./images/pump-alarm.png'),
    alertAlarm: require('./images/alert-alarm.png'),
    rightArrow: require('./images/rightArrow.png'),
    criticalIcon: require('./images/criticalIcon.png'),
    warningIcon: require('./images/warningIcon.png'),
    errorIcon: require('./images/errorIcon.png'),
    informationIcon: require('./images/informationIcon.png'),
    checkIcon: require('./images/checkIcon.png'),
};

export { IMAGES };
