import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import CircularIndeterminate from '../Progress/CircularProgress';
import AlertsTable from './AlertsTable';

export interface Alarm {
    datetime: string | null;
    category: string | null;
    id: number;
    code: string | null;
    group: string;
    type: string;
    level: string;
    text: string;
}

interface CriticalAlertsProps {
    messageData: Alarm[];
    loading: boolean;
    activeSection?: string;
}

const CriticalAlerts: React.FC<CriticalAlertsProps> = (props) => {
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const [page, setPage] = useState<number>(1);
    const itemsPerPage = isPortrait ? 3 : 5;
    const criticalAlerts = props.messageData.filter((item) => item.level === 'error');
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const paginatedAlerts = criticalAlerts.slice(startIdx, endIdx);

    return (
        <Box data-testid="critical-alerts">
            <Box>
                {props.loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularIndeterminate />
                    </Box>
                ) : criticalAlerts.length === 0 ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        No critical alerts available
                    </Box>
                ) : (
                    <AlertsTable
                        messageData={paginatedAlerts}
                        setPage={setPage}
                        page={page}
                        totalAlerts={criticalAlerts.length}
                        itemsPerPage={itemsPerPage}
                        activeSection={props.activeSection}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CriticalAlerts;
